import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBoltLightning, faFileExport, faTrophy, faUsers, faShare } from '@fortawesome/free-solid-svg-icons';
import { getQueryParam } from '../../config/helper';
import { useLocation, useHistory, NavLink, Link } from 'react-router-dom';
import { useDisconnect } from "wagmi";
import React from 'react';

const SideNavbar = (props) => {
    const { disconnect } = useDisconnect()
    let history = useHistory()
    const location = useLocation();
    const logout = () => {
        localStorage.setItem("logout", true);
        localStorage.setItem("firstVisit", false);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem("twitter_user_secret")
        localStorage.removeItem("twitter_user_token")
        localStorage.removeItem('WalletAddress');
        disconnect()
        history.push('/');
    }
    let queryAddress = getQueryParam(location, 'address');
    const currentUser = localStorage.getItem("WalletAddress");
    return (
        <>
            <div className='settings-sidenav'>
                <ul>
                    <li>
                        <NavLink to="/settings/profile">
                            <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faUser} /></span>
                            <span>Profile</span>
                        </NavLink>
                    </li>
                    {(currentUser === queryAddress) || !queryAddress ?
                        <> <li>
                            <NavLink to="/settings/activity">
                                <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faBoltLightning} /></span>
                                <span>Activity</span>

                            </NavLink>
                        </li>
                            <li>
                                <NavLink to="/settings/submissions">
                                    <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faFileExport} /></span>
                                    <span>Submissions</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings/achievements">
                                    <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faTrophy} /></span>
                                    <span>Achievements</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={props.address ? `/settings/leaderboard?address=${props.address}` : "/settings/leaderboard"}>
                                    <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faUsers} /></span>
                                    <span>Leaderboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings/referral">
                                    <span className='me-xl-3 me-2'><FontAwesomeIcon icon={faShare} /></span>
                                    <span>Referral Space</span>
                                </NavLink>
                            </li>
                            <li>
                                <Link className="main-btn-style" onClick={() => logout()} to="#">Logout</Link>
                            </li>

                        </> : ""
                    }
                </ul>
            </div>
        </>
    )
}

export default SideNavbar
