import { GOT_RES, NFT_CREATED, DELETED_POST, POST_READ, POST_DELETED, SET_NOTIFICATIONS, SET_UNSEENEVENTS, EDIT_STATUS, GET_STATUS, REGISTER, SET_LOADER, PROF_DATA_COUNT, SET_ALL_USERS, GET_USER_POSTS_LIST, CLEAR_USER_POST_DATA, USER_WALL_INFO, USER_CARD_INFO, GET_STATUS_VIEW, GET_USER_PROFILE, GET_USER_TASKS } from "../actionTypes";
import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import { apiHelper, apiHelperLive, gamificationApiHelper } from "../apiHelper";


///////////////////// user actions///////////////////

export const getUserStatus = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users?address=${data.address}&userId=${data.userId}&platform=${data.platform ? data.platform : 'Treasurebox'}`, "")
        if (res) {
            if (res?.data) {
                if (data?.profileView !== undefined && data?.profileView === true) {
                    let { data } = res
                    dispatch({
                        type: GET_STATUS_VIEW,
                        payload: data
                    })
                }
                else {
                    let { data } = res
                    dispatch({
                        type: GET_STATUS,
                        payload: data
                    })
                }
            }
            dispatch({
                type: SET_LOADER,
                payload: false
            })
        }
    } catch (error) {
        console.log(error)
    }
}


export const deletePost = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/delete/${postId}`, "");
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        if (res.data) {
            dispatch({
                type: DELETED_POST,
                payload: true
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const getUserDataCount = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/profile-info/${data.address}?category=${data.category}&tempType=${data.temType}&post_find=${data.search}&tag=${data.tag}&type=${data.type}&offset=${data.offset}&limit=${data.limit}&filterBy=${data.filter}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                let { data } = res
                dispatch({
                    type: PROF_DATA_COUNT,
                    payload: data
                })
                dispatch({
                    type: DELETED_POST,
                    payload: false
                })
                dispatch({
                    type: GET_USER_POSTS_LIST,
                    payload: data.userData
                })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const getUserWallInfo = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/profile-info/${data.address}?dtype=${data.filter || 'all'}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                let { data } = res
                dispatch({
                    type: USER_WALL_INFO,
                    payload: data?.tBox
                })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const getUserCardsInfo = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        let res = await apiHelper("get", `/v1/treasurebox/posts?userId=${data.address}&type=${data.type}&dtype=all`, "")
        if (res) {

            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                let { relatedPosts } = res?.data ? res.data : ""
                dispatch({
                    type: USER_CARD_INFO,
                    payload: relatedPosts
                })

                dispatch({
                    type: SET_LOADER,
                    payload: false
                })

            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const getNotifications = (address) => async (dispatch) => {
    try {
        if (ENV.getUserKey("WalletAddress")) {
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            let res = await apiHelper("get", `/v1/notifications/list-by-id/${address}`, "", "")

            if (res) {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: POST_DELETED,
                    payload: false
                })
                dispatch({
                    type: POST_READ,
                    payload: false
                })
                if (res?.data) {
                    let { data } = res
                    dispatch({
                        type: SET_NOTIFICATIONS,
                        payload: data
                    })
                }
            }
        }

    } catch (error) {
        console.log(error)
    }
}
export const deleteNotification = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/notifications/delete/${postId}`, "", "")

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                dispatch({
                    type: POST_DELETED,
                    payload: true
                })
            }
        }

    } catch (error) {
        console.log(error)
    }
}
export const readNotification = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/notifications/${postId}`, "", "")

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                dispatch({
                    type: POST_READ,
                    payload: true
                })
            }
        }

    } catch (error) {
        console.log(error)
    }
}
export const editUserStatus = (data, history) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        data.formData.platform = "Treasurebox"

        let res = await apiHelper("put", `/v1/users?address=${data.address}&&userId=${data.userId}`, data.formData, '')
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res.data) {
                let { data } = res

                if (data.code === 404) {
                    let walletAddress = ENV.getUserKey("WalletAddress")
                    dispatch(RegisterUser({
                        address: walletAddress,
                        name: "",
                        image: ""
                    }))
                } else if (data.code === 409) {
                    toast.error("username alredy exists")
                } else {
                    dispatch({
                        type: EDIT_STATUS,
                        payload: data
                    })
                    toast.success("Profile updated successfully!")
                    history.push('/');
                }
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const RegisterUser = (user, history, location) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let userTasks = JSON.parse(localStorage.getItem("userTasks"));
        let event = userTasks.find(event => event.eventType === "register");
        user.points = event?.points
        user.pointType = event?.pointType
        user.pointEventName = event?.name
        user.pointEventId = event?._id
        event = userTasks.find(event => event.eventType === "refer_new_user");
        user.inviterPoints = event?.points
        user.inviterPointType = event?.pointType
        user.inviterPointEventName = event?.name
        user.inviterPointEventId = event?._id
        user.platform = "Treasurebox"
        let enc = ENV.encryptPayload(user)
        let data = {
            enc: enc
        }
        let res = await apiHelper("post", `/v1/users?address=${user.address}`, data)
        if (res) {

            dispatch({
                type: SET_LOADER,
                payload: false
            })
            localStorage.setItem('WalletAddress', user.address)
            localStorage.setItem("userId", user.userId)


            if (res?.data) {
                let { data } = res
                let tok = data?.data?.accessToken
                if (tok === undefined) {
                    const options = {
                    }
                    localStorage.removeItem('WalletAddress')
                    localStorage.removeItem("userId")
                    localStorage.removeItem("token")
                    toast.error("No access Token, Please try again.", options)
                    history.push('/login')
                } else {
                    localStorage.setItem("token", tok)
                    dispatch({
                        type: REGISTER,
                        payload: data
                    })
                    if (location?.state?.from) {
                        let from = location.state.from;
                        history.push(`${from.pathname + from.search}`)
                    } else {
                        history.push("/");
                    }

                }

            }
        }
    } catch (error) {
        console.log(error)
        toast.error("Some thing went wrong. please try later...")
    }
}


export const getLeaderboard = (user) => async (dispatch) => {

    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        dispatch({
            type: GOT_RES,
            payload: false
        })
        let res = await apiHelper("get", `/v1/users/tboxLeaderBoardStats?from=${user.query?.from}&to=${user.query?.to}&address=${user.query?.address}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            dispatch({
                type: GOT_RES,
                payload: true
            })
            if (res?.data) {
                let { data } = res

                dispatch({
                    type: SET_ALL_USERS,
                    payload: data.leaderBoard
                })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const postEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let userTasks = JSON.parse(localStorage.getItem("userTasks"));
        const event = userTasks.find(event => event.eventType === "sharing");
        data.points = event?.points
        data.pointType = event?.pointType
        data.pointEventName = event?.name
        data.pointEventId = event?._id
        let enc = ENV.encryptPayload(data)
        let data_enc = {
            enc: enc
        }
        let res = await apiHelper("post", `/v1/treasurebox/event`, data_enc)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

        }
    } catch (error) {
        console.log(error)
    }
}
export const postShared = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("put", `/v1/treasurebox/share/${data.postId}`, data)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export const postFollowersEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let userTasks = JSON.parse(localStorage.getItem("userTasks"));
        const event = userTasks.find(event => event.eventType === "social_followers");
        data.points = event?.points
        data.pointType = event?.pointType
        data.pointEventName = event?.name
        data.pointEventId = event?._id
        let enc = ENV.encryptPayload(data)
        let data_enc = {
            enc: enc
        }
        setTimeout(async () => {
            let res = await apiHelper("post", `/v1/treasurebox/social/points-update`, data_enc)
            if (res) {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })

            }
        }, 10000)
    } catch (error) {
        console.log(error)
    }
}

export const clearUserData = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_USER_POST_DATA
        })
    } catch (error) {
        console.log(error.message);
    }
}

export const createNFT = (data) => async (dispatch) => {
    try {

        dispatch({
            type: NFT_CREATED,
            payload: false
        })
        let res = await apiHelperLive("post", `/nfts/create`, data)

        if (res) {
            if (res?.data) {
                let { data } = res

                if (res?.data?.success === true) {
                    dispatch({
                        type: NFT_CREATED,
                        payload: true
                    })
                }
                else {
                    toast.error(data.message)
                }
                if (res.data) {
                    return res.data
                }
            }
        }


    } catch (error) {
        console.log(error)
    }
}

export const getUserProfile = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/profile/${data?.address}?platform=${data?.platform}&sort=${data?.sort}&post_find=${data.search}&offset=${data.offset}&limit=${data.limit}&tag=${data.tag}`)

        if (res.data) {
            dispatch({
                type: GET_USER_PROFILE,
                payload: res.data.data
            })
        }
        else {
            toast.error("Error in response");
        }
        dispatch({
            type: SET_LOADER,
            payload: false
        })
    } catch (error) {
        console.log(error.message);
    }
}

export const getUserTasks = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await gamificationApiHelper("get", `/v1/task/platform/5`) //5 for treasurebox

        if (res.data) {
            dispatch({
                type: GET_USER_TASKS,
                payload: res.data.events
            })
        }
        else {
            toast.error("Error in response");
        }
        dispatch({
            type: SET_LOADER,
            payload: false
        })
    } catch (error) {
        console.log(error.message);
    }
}

export const postNftEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await gamificationApiHelper("post", `/v1/users/event`, data)

        if (!res.data) {
            toast.error("Error in response");
        }
        dispatch({
            type: SET_LOADER,
            payload: false
        })
    } catch (error) {
        console.log(error.message);
    }
}