import { React, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

function WelcomeModel(props) {
    const history = useHistory();
    const [show, setShow] = useState(true)
    const handleClose = () => {
        setShow(false);
    }

    const handleLogin = () => {
        props.setWelocmeModel(false)
        setShow(false)
        history.push('/format');
    }

    return (
        <div className='swal-model'>
            <Modal centered className="custom-modal connect-wallet" show={show} onHide={handleClose} >
                <Modal.Header>
                    <div className="image">
                        <span className='icon'><FontAwesomeIcon icon={faBullhorn} /></span>
                    </div>
                    <Button className="connect-btn btn ms-2" onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-4 modal-main-body'>
                        <Modal.Title>Earn 250 Points</Modal.Title>
                        <p className='mb-1'>Create Profile <strong>Earn 250 Points!</strong></p>
                        <p>Don't forget to share it with your friends.</p>
                    </div>
                    <div className='mt-3 pt-3 d-flex justify-content-center align-items-center flex-wrap'>
                        <Button className='text-start btc ms-2 mb-1  main-btn-style' onClick={handleClose}>
                            Close
                        </Button>
                        <Button className='text-start btc ms-2 mb-1 main-btn-style' onClick={handleLogin}>
                            Add Post
                        </Button>
                        <div className='social-btns d-flex'>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default WelcomeModel