import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './redux/combineReducers'
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer limit={1} />
    <App />
  </Provider>, document.getElementById('root')
);
serviceWorkerRegistration.unregister();

reportWebVitals();
