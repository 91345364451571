import axios from "axios";
import { ENV } from '../config/config';
import swal from 'sweetalert2';

let baseUrl = ENV.serverUrl
let liveBaseUrl = ENV.liveServerUrl
let liveTBoxServerUrl = ENV.liveTBoxServerUrl
let liveGamifServerUrl = ENV.liveGamifServerUrl
let wpTemplateBaseUrl = "http://localhost/hexwp/wp-json/wp/v2"

async function apiHelper(apiType, path, data) {
    if (baseUrl === undefined || !baseUrl) {
        baseUrl = ""
    }
    const token = localStorage.getItem("token")
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${baseUrl + path}`,
                data,
                headers: {
                    'token': token
                }
            })
            return response
        } catch (error) {
            let errorStatus = error.response.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }else if(errorStatus === 401){
                swal.fire({
                    title: 'Server Error',
                    text: "Token expired please login again",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
                setTimeout(() => {
                    ENV.logout();
                }, 3000);
            }
        }
    }
}

async function apiHelperLive(apiType, path, data) {
    if (liveBaseUrl === undefined || !liveBaseUrl) {
        liveBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${liveTBoxServerUrl + path}`,
                data,
                headers: {
                    'Authorization': `${ENV.Authorization}`,
                    'x-auth-token': ENV.x_auth_token
                }
            })
            return response
        } catch (error) {
            if (error.response) {
                return error.response;
            }
            else if (error.responce.status === 500 || error.responce.status === 502 || error.responce.status === 503 || error.responce.status === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }
}


async function gamificationApiHelper(apiType, path, data) {
    if (liveGamifServerUrl === undefined || !liveGamifServerUrl) {
        liveGamifServerUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${liveGamifServerUrl + path}`,
                data
            })
            return response
        } catch (error) {
            if (error.response) {
                return error.response;
            }
            else if (error.responce.status === 500 || error.responce.status === 502 || error.responce.status === 503 || error.responce.status === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }
}

async function apiHelperForTemplate(apiType, path, data) {

    if (wpTemplateBaseUrl === undefined || !wpTemplateBaseUrl) {
        wpTemplateBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {

             await fetch(`${wpTemplateBaseUrl + path}`, {
                method: apiType,
                headers: {
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJhZG1pbiIsImlhdCI6MTY1NjkzNjI4NCwiZXhwIjoxODE0NjE2Mjg0fQ.O5Tu3zonBSx6leMIRROEuSJt0UkolqfYoTzk2kkpy2s`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },

                body: JSON.stringify(data)
            });
        } catch (error) {
            let errorStatus = error.responce.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }
}
async function apiMultipart(apiType, path, data) {
    if (wpTemplateBaseUrl === undefined || !wpTemplateBaseUrl) {
        wpTemplateBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${wpTemplateBaseUrl + path}`,
                data,
                headers: {
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJ0cmVhc3VyZWJveCIsImlhdCI6MTY1NTc5OTM5MSwiZXhwIjoxODEzNDc5MzkxfQ.Wf6YT7vGLGnbPOvKfpr0AYb42K9L7vAjW_44X6zRY1U`,
                }
            })
            return response
        } catch (error) {
            let errorStatus = error.responce.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }
}

export { apiHelper, apiHelperLive, apiHelperForTemplate, apiMultipart, gamificationApiHelper };
