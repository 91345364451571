import { WALLET_PROVIDER,WALLET_LOADER } from "../actionTypes";



const walletReducer = (state = 0, action) => {
    if(action.type === WALLET_PROVIDER){
     return {
        ...state,
        walletType: action.payload};
    } else if (action.type === WALLET_LOADER){
    return {
        ...state,
        walletLoader : action.payload
    }
} else {
        return state
    }
}

export default walletReducer;