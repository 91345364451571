import React, { useEffect, useRef, useState } from 'react';
import WaterMark from "../../assets/images/waterMark.svg"
import Avatar from "../../assets/images/avatar.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faExchange } from '@fortawesome/free-solid-svg-icons'
import {  getCategories } from '../../redux/treasureBox/action';
import Select from 'react-select'
import EyeView from "../../assets/images/ionic-eye.svg"
import { Link, useLocation, useParams } from "react-router-dom"
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParam } from '../../config/helper';
import InfiniteScroll from "react-infinite-scroll-component";
import CountDown from '../posts/post/CountDown';
import MobileDetect from 'mobile-detect';
import { clearUserData, getUserProfile } from '../../redux/user/action';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import moment from 'moment';
import Post from "../../assets/images/post-placeholder.svg"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
let md = new MobileDetect(window.navigator.userAgent);



function UserProfile() {
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [initPostLoader, setInitPostLoader] = useState(true);
    let offset = useRef(0)
    let limit = md.mobile() ? 5 : 10;
    const dispatch = useDispatch();
    const {profile_address} = useParams();
    const [sort, setSort] = useState("");
    const [copied, setCopied] = useState(false)
    let user_profile = useSelector(state => state?.userData?.user_profile)
    let treasureboxes = useSelector(state => state?.userData?.user_profile_posts)
    let search = useSelector(state => state?.treasureBoxData?.search)
    let location = useLocation()
    let category = getQueryParam(location, 'category')
    let urlTag = getQueryParam(location, 'tag')
    let temType = getQueryParam(location, 'temType')
    let treasureboxesCount = useSelector(state => state?.treasureBoxData?.treasureBoxCount)
    const loaderActive = useSelector(state => state.userData.loaderActive)

    const options = [
        { value: 'newest', label: 'NEWEST' },
        { value: 'popular', label: 'POPULAR' },
      ];

      useEffect(async () => {
        dispatch(clearUserData());
        offset.current = 0
        treasureboxesCount = undefined
        fetchposts();
    }, [window.location.search, sort])

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    useEffect(() => {
        if (copied) {
            toast.success("Wallet address copied to clipboard ")
            setCopied(false)
        }
    }, [copied])
    
    const fetchposts = async (searchPost) => {

        let data = { category: "", temType: "", tag: '', search: searchPost, offset: offset.current, limit: limit, address: profile_address, platform: "Treasurebox", sort: sort.value }
        if (category) {
            data.category = category
            data.temType = ""
        }
        if (urlTag) {
            data.tag = urlTag
            data.temType = ""
        } 
        if (temType) {
            data.category = category
            data.temType = temType
        }
        setInitPostLoader(true)
        dispatch(getUserProfile(data))
        setInitPostLoader(false)


        offset.current = offset.current + 1
        if (treasureboxesCount && (offset.current * limit) < treasureboxesCount) {
            setHasMoreItems(true)
        } else if (treasureboxesCount === undefined) {
            setHasMoreItems(true)
        } else if (treasureboxesCount) {
            setHasMoreItems(false)
        }
    }

    function formatNumber(number) {
        let result = "";
        if (number >= 1000000) {
          result = (number / 1000000).toFixed(1) + "M";
        } else if (number >= 1000) {
          result = (number / 1000).toFixed(1) + "K";
        } else {
          result = number;
        }
        return result;
      }

    let lastSeenText = "";
    const diffInDays = moment().diff(user_profile?.lastSeen, 'days');
    const diffInHours = moment().diff(user_profile?.lastSeen, 'hours');
    const diffInMinutes = moment().diff(user_profile?.lastSeen, 'minutes');

    if (diffInDays > 0) {
        lastSeenText = `${diffInDays} days ago`;
    } else if (diffInHours > 0) {
        lastSeenText = `${diffInHours} hours ago`;
    } else {
        lastSeenText = `${diffInMinutes} minutes ago`;
    }

  return (
    <>
        <div className='user-profile'>
            <div className='profile-banner d-flex align-items-center justify-content-center'>
                <strong>Myntist</strong>
            </div>
            <div className='treasurebox-sec pt-0 px-3'>
                <div className='d-lg-flex align-items-center mb-4'>
                    <div className='user-dp me-lg-3'>
                        <img  src={user_profile?.picture ? user_profile?.picture : Avatar} alt="Dp" className='img-fluid'/>
                    </div>
                    <div className='user-info d-md-flex align-items-center justify-content-between pt-md-5 pt-4 mb-0 flex-1'>
                        <ul className='d-flex align-items-center list-unstyled mb-md-0 mb-3'>
                            <li className='d-flex align-item-center'>
                                <p className='me-1'>Views:</p>
                                <strong>{formatNumber(user_profile?.total_views)}</strong>
                            </li>
                            <li className='d-flex align-item-center'>
                                <p className='me-1'>Posts:</p>
                                <strong>{formatNumber(user_profile?.total_posts)}</strong>
                            </li>
                            <li className='d-flex align-item-center'>
                                <p className='me-1'>Points:</p>
                                <strong>{formatNumber(user_profile?.total_points)}</strong>
                            </li>
                        </ul>
                        <div className="field-wrapper">
                            <p className='user-profile-address'>{profile_address}</p>

                            <CopyToClipboard text={profile_address}
                                onCopy={() => setCopied(true)}>
                                <div className="copy-icon">
                                    <FontAwesomeIcon icon={faCopy} />
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className='user-name'>
                    <div className='d-flex align-items-baseline justify-content-md-start justify-content-between'>
                        <strong className={user_profile?.firstName || user_profile?.lastName ? 'me-3' : ''}>{user_profile?.firstName} {user_profile?.lastName}</strong> 
                        <div className='status'><span className='me-2'>Active</span><span>{lastSeenText}</span></div>
                    </div>
                    <span className='unique-user d-block mb-3'>@{user_profile?.name}</span>
                    <p className='user-bio'>{user_profile?.about}</p>
                </div>
                
            </div>
        
        </div>
        <div className='treasurebox-sec pt-0'>
            <div className='posts-header d-sm-flex align-items-center justify-content-between flex-wrap mb-3'>
                <h2 className='d-block mb-sm-0 mb-2'>Posts</h2>
                <div>
                    <Select
                        onChange={setSort}
                        options={options}
                        classNamePrefix="submission"
                    />
                </div>
            </div>
            <div className='user-posts px-3'>
            {
                            treasureboxes && treasureboxes.length > 0 ?
                                <>



                                    <div id={treasureboxes ? category ? category : urlTag ? urlTag : temType ? temType : '' : ''} className='nft-sec4  nft-sec d-sm-flex align-items-center justify-content-between flex-wrap'>
                                        <InfiniteScroll
                                            dataLength={treasureboxes?.length}
                                            next={() => fetchposts(search)}
                                            hasMore={hasMoreItems}
                                        >
                                            {treasureboxes.length > 0 &&
                                                <>
                                                    <Row>
                                                        {treasureboxes.map((treasureBox, index) => (

                                                            <Link key={uuidv4()} to={`/post-view/${treasureBox?._id}?type=${treasureBox?.templateType}`} className="wrapper4">
                                                                <div className=''>
                                                                    <div className='inner-wrapper'>
                                                                        <CountDown boostTill={treasureBox?.boostedUntil} />
                                                                        {treasureBox?.templateType != 'gif' && treasureBox?.templateType != 'video' &&
                                                                            <div className='image water-mark-wrap'>
                                                                                <img loading='lazy' src={treasureBox?.image} alt="" className='img-fluid w-100 img-fixed' />
                                                                                <img src={WaterMark} alt="watermark" className='img-fluid water-mark'/>
                                                                            </div>
                                                                        }
                                                                        {treasureBox?.gif &&
                                                                            <div className='image water-mark-wrap'>
                                                                                <img loading='lazy' src={treasureBox?.image} alt="" className='img-fluid w-100 img-fixed' />
                                                                                <img src={WaterMark} alt="watermark" className='img-fluid water-mark'/>
                                                                            </div>
                                                                        }
                                                                        {treasureBox?.file &&
                                                                            <div className='image water-mark-wrap video-button'>
                                                                                <img loading='lazy' src={treasureBox?.image} alt="" className='img-fluid w-100 img-fixed' />
                                                                                <img src={WaterMark} alt="watermark" className='img-fluid water-mark'/>
                                                                            </div>
                                                                        }

                                                                        <div className='nft-meta d-flex justify-content-between align-items-center'>
                                                                            <div className='views-votes d-flex align-items-center'>
                                                                                <div className='view-votes-wrapper d-flex align-items-center me-2'>
                                                                                    <div className='views me-1'>
                                                                                        <img src={EyeView} alt="views" className="img-fluid" />
                                                                                    </div>
                                                                                    <span>{treasureBox?.views}</span>
                                                                                </div>
                                                                                <div className='votes'>
                                                                                    {treasureBox?.voting === false ? <>
                                                                                        <FontAwesomeIcon style={{ transform: 'rotate(90deg)' }} className='me-1' icon={faExchange} /><span>{treasureBox?.likes}</span>
                                                                                    </> : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div >
                                                                        <div className='tags d-flex flex-wrap'>
                                                                            {treasureBox?.tags && treasureBox?.tags.split(',').map((tag, index) => (
                                                                                <span key={uuidv4()} className='post-tags'> <Link to={`/user-profile/${profile_address}?tag=${tag}`} > #{tag}</Link></span>
                                                                            ))}
                                                                        </div>
                                                                        <h3 className='ps-1'>{treasureBox?.title}</h3>
                                                                    </div >
                                                                </div >
                                                            </Link >
                                                        ))
                                                        }


                                                    </Row >
                                                </>
                                            }
                                        </InfiniteScroll >

                                    </div >
                                </> : loaderActive || initPostLoader ?
                                    <>
                                        <div id={category ? category : urlTag ? urlTag : temType ? temType : ''} className='nft-sec4  nft-sec d-sm-flex align-items-center justify-content-between flex-wrap'>
                                            {category && category.length > 0 &&
                                                <h2 class="p-0 mb-sm-3 mb-2 d-flex flex-wrap h-border category-h"><span>Category:</span> <span>{category}</span></h2>
                                            }
                                        </div>
                                        <div className='post-skeleton'>
                                            <SkeletonTheme
                                                baseColor="transparent"
                                                highlightColor="#ffffff"
                                                borderRadius="0.5rem"
                                            >
                                                <Skeleton width={305} height={335} count={5} borderRadius="5"/>
                                                <Skeleton width={305} height={335} count={5} borderRadius="5"/>
                                            </SkeletonTheme>

                                        </div></> :
                                    <div >{!initPostLoader && !loaderActive && treasureboxes?.length == 0 &&
                                        <>
                                            <div id={category ? category : urlTag ? urlTag : temType ? temType : ''} className='nft-sec4  nft-sec d-sm-flex align-items-center justify-content-between flex-wrap'>
                                                {category && category.length > 0 &&
                                                    <h2 class="p-0 mb-sm-3 mb-2 d-flex flex-wrap h-border category-h"><span>Category:</span> <span>{category}</span></h2>
                                                }
                                            </div>
                                            <div className='not-found sec4-not-found'>
                                                <div className='post-img'>
                                                    {
                                                        md.mobile() ?
                                                            <img height={80} width={80} loading='lazy' src={Post} alt="post placeholder" className='img-fluid' />
                                                            :
                                                            <img loading='lazy' src={Post} alt="post placeholder" className='img-fluid' />
                                                    }                                            </div>
                                                <p>No post to show</p>
                                            </div>
                                        </>
                                    }</div>
                        }
            </div>
        </div>
    </>
  )
}

export default UserProfile