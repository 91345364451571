import {React, useState} from 'react';
import { Col, Container, Row, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faReddit, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import MobileDetect from 'mobile-detect';
import logo1 from "../../assets/images/logo1.svg"
let md = new MobileDetect(window.navigator.userAgent);

const Footer = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleContent = () => {
        setShowMore(!showMore);
    };
    return (
        <>
            <section className='footer-sec'>
                <div className='footer-sec-padding'>
                    <Container fluid>
                        <Row>
                            <Col lg={4}>
                                <div className='footer-wrapper me-lg-2'>
                                    <Navbar.Brand href='/' className='d-flex align-items-center'>
                                        <div className="footer-logo me-2">
                                            <img height={50} width={50} loading='lazy' src={`/images/logo.svg`} alt="logo" className="img-fluid" />
                                        </div>
                                        <span className='flex-1'>Myntist Treasure Box</span>
                                    </Navbar.Brand>
                                    <>
                                        <div className={`footer-content ${showMore ? 'show-more' : ''}`}>
                                            <p className='mb-2'>Myntist Treasure Box is a unique platform where users can earn rewards by creating, posting, and sharing content on different social media platforms. From personality quizzes to trivia quizzes, from classic internet listicles to open lists, feel free to add different kinds of posts on this distinctive platform. Toggle among different tags to lay your eyes on various posts. In addition, you can use several relevant tags in your post to stand out. Not only this, but you can also share those posts on other social media platforms seamlessly.</p>
                                            <p className='mb-2'>Also, you can become a part of our mailing list to keep yourself apprised of our NFT drops,  newest feature releases, tips, and tricks to navigate MYNTIST.</p>
                                            <p className='mb-2'>Visit the site and join us today!</p>
                                        </div>
                                        {!showMore && (
                                            <span className='show-btn' onClick={toggleContent}>Show More</span>
                                        )}
                                        {showMore && (
                                            <span className='show-btn' onClick={toggleContent}>Show Less</span>
                                        )}
                                    </>
                                    <h5>Join the Community</h5>
                                    <ul className='social-links d-flex flex-wrap pe-xxl-5 pe-xl-4 pe-lg-3 mb-xxl-4 mb-3'>
                                        <a href='https://www.facebook.com/Myntist'><li className='d-flex align-items-center justify-content-center me-2 fa'><FontAwesomeIcon icon={faFacebookF} /></li></a>
                                        <a href='https://www.youtube.com/channel/UCNmA1NF0EDzG_tRiRG5o8Uw' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 yt'><FontAwesomeIcon icon={faYoutube} /></li></a>
                                        <a href='https://www.instagram.com/myntist/' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 in'><FontAwesomeIcon icon={faInstagram} /></li></a>
                                        <a href='https://twitter.com/myntist?s=20&t=e5BMBb0SqOVPtqmerNsW5A' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 tw'><FontAwesomeIcon icon={faTwitter} /></li></a>
                                        <a href='https://www.reddit.com/user/Myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 re'><FontAwesomeIcon icon={faReddit} /></li></a>
                                        <a href='https://www.tiktok.com/@myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 gi'><FontAwesomeIcon icon={faTiktok} /></li></a>
                                    </ul>
                                    <h5>Explore other Platforms</h5>
                                    <a href='https://gamification.arhamsoft.org' target="_blank" className='gamification-logo d-flex align-items-center'>
                                        <span className='me-2'>
                                            
                                                <img   loading='lazy'src={logo1} alt="gamification" className='img-fluid' />
                
                                        </span>
                                        <span>Gamification</span>
                                    </a>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <Row>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>Important Links</h5>
                                            <ul className='footer-links-ul'>
                                                <li><Link to='/'>TreasureBox</Link></li>
                                                <li><Link to='/board'>Leaderboard</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>My Account</h5>
                                            <ul className='footer-links-ul'>
                                                <li><Link to='/settings/profile' >Profile</Link></li>
                                                <li><Link to='/your-wall' >Your Wall</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>Company</h5>
                                            <ul className='footer-links-ul'>
                                                <li><a href='https://nft.myntist.com/contact' target="_blank">Contact Us</a></li>
                                                <li><Link to='/privacy-policy' >Privacy Policy</Link></li>
                                                <li><Link to='/terms-and-conditions' >Terms and Conditions</Link></li>
                                                <li><a href='https://nft.myntist.com/how-it-works?url=Getting-Started'  target="_blank">How It Works</a></li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <hr />
                <div className='all-right-services text-center'>
                    <p>All Right Reserved by Myntist</p>
                </div>
            </section>

        </>
    )
}

export default Footer