import {GOT_RES,NFT_CREATED, NFT_SOLD_LOADER, DELETED_POST, POST_READ, POST_DELETED, SET_NOTIFICATIONS, SET_UNSEENEVENTS, SET_ALL_USERS, SET_LOADER, EDIT_STATUS, GET_STATUS, REGISTER, PROF_DATA_COUNT, GET_USER_POSTS_LIST, CLEAR_USER_POST_DATA,USER_WALL_INFO, USER_CARD_INFO,GET_STATUS_VIEW, GET_USER_PROFILE, GET_USER_PROFILE_POSTS, GET_USER_TASKS } from '../actionTypes';

const initialState = {
    success: false,
    loaderActive: false,
    profileDataCounts: [],
    postDeleted: false,
    postRead: false,
    userPosts: [],
    nftCreated: false,
    userWallInfo:[],
    gotStats:false,
    user_profile: [],
    user_profile_posts: [],
    tasks:[]
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GOT_RES:
            return {
                ...state,
                gotStats: action.payload
            }
        case REGISTER:
            return {
                ...state,
                profile: action.payload,
            }
        case PROF_DATA_COUNT:
            return {
                ...state,
                profileDataCounts: action.payload,
            }

        case GET_STATUS:
            return {
                ...state,
                profile: action.payload,
                success: false,
            }
        case GET_STATUS_VIEW:
            return {
                ...state,
                profileView: action.payload,
                success: false,
            }
        case EDIT_STATUS:
            return {
                ...state,
                profile: action.payload,
                success: true
            }
        case SET_ALL_USERS:
            return {
                ...state,
                allUsers: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload,
            }
        case NFT_SOLD_LOADER:
            return {
                ...state,
                nftSoldLoader: action.payload
            }
        case NFT_CREATED:
            return {
                ...state,
                nftCreated: action.payload
            }

        case SET_UNSEENEVENTS:
            return {
                ...state,
                unseenTboxCount: action.payload
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notification: action.payload
            }
        case POST_DELETED:
            return {
                ...state,
                postDeleted: action.payload
            }
        case POST_READ:
            return {
                ...state,
                postRead: action.payload
            }

        case DELETED_POST:
            return {
                ...state,
                deletedPost: action.payload,
            }
        case GET_USER_POSTS_LIST:
            return {
                ...state,
                userPosts: state.userPosts.concat(action.payload)
            }
        case USER_WALL_INFO:
            return {
                ...state,
                userWallInfo: action.payload
            }
        case USER_CARD_INFO:
            
            return {
                ...state,
                userWallInfo: {
                    ...state.userWallInfo,
                    posts: action.payload
                }
            }
        case CLEAR_USER_POST_DATA:
            return {
                ...state,
                userPosts: [],
                user_profile:[],
                user_profile_posts:[]
            }
        case GET_USER_PROFILE:
            return {
                ...state,
                user_profile: action.payload.user_profile[0],
                user_profile_posts: state.user_profile_posts.concat(action.payload.user_posts[0].posts),
            }
        case GET_USER_TASKS:
            return {
                ...state,
                tasks: action.payload
            }

        default:
            return state
    }
}

export default userReducer
