import { ENV } from "./config";

let watermarkStr = ENV.watermarkStr

const getAccount = async () => await window.ethereum.request({ method: 'eth_accounts' })[0] || false;
const getQueryParam = (location, name) => {
    let searchParams = new URLSearchParams(location.search)
    let queryParam = searchParams.get(name)
    return queryParam
}

const transformation = (media, platform) => {
    let query = "f_auto,q_auto"
    if (platform == "twitter") {
        if (media?.split("upload/")[0].includes('image')) {
            query = query + ',h_400,w_400,q_auto'
        }
        if (media?.split("upload/")[0].includes('video')) {
            query = query + ',h_1024,w_1280,q_20,eo_130,so_0.0,w_480,h_270,fps_24,ac_mp3'
        }
    } else if (platform == "instagram") {
        if (media?.split("upload/")[0].includes('image')) {
            query = query + ',h_400,w_400,q_30'
        }
        if (media?.split("upload/")[0].includes('video')) {
            query = query + ',h_720,w_1280,eo_60,so_0.0,q_20,br_110k,h_400,w_400,fps_30,ac_none'
        }
    } else if (platform == "facebook") {
        if (media?.split("upload/")[0].includes('image')) {
            query = query + ',h_400,w_400,q_30'
        }
        if (media?.split("upload/")[0].includes('video')) {
            query = query + ',h_720,w_1280,eo_130,so_0.0,br_120k'
        }
    } else if (platform == "tiktok") {
        if (media?.split("upload/")[0].includes('image')) {
            query = query + ',h_400,w_400,q_30'
        }
        if (media?.split("upload/")[0].includes('video')) {
            query = query + ',h_720,w_1280,eo_30,so_0.0'
        }
    }
    return query
}

const createWatermarkUrl = (url, templateType) => {
    let imageUrl;
    if (templateType != 'video') {
        imageUrl = url?.split('/upload/')[0] + watermarkStr + url?.split('/upload/')[1];
    }else {
        imageUrl = url?.split('/upload/')[0] + watermarkStr + url?.split('/upload/')[1].split(/\.(?=[^.]+$)/)[0];
    }
    imageUrl = url.replace('/upload/', '/upload/w_800/');
    return imageUrl;
}

export { getAccount, getQueryParam, transformation, createWatermarkUrl }

