import React from "react";
import Header from "../components/treasureBox/header";
import Footer from "../components/treasureBox/footer";

class Layout8 extends React.Component {
    render() {
        const { children } = this.props
        return (
            <>
                <Header/>
                <div className="treasurebox-sec mw-100">
                    {children}
                </div>
                <Footer/>
            </>
        )
    }
}
export default Layout8;