import io from 'socket.io-client'
require('dotenv').config();
const dataEncryptionKey = process.env.REACT_APP_ENC_KEY

const CryptoJS = require("crypto-js");

export const ENV = {
    serverUrl: process.env.REACT_APP_SERVER_URL,
    sharingUrl: process.env.REACT_APP_TREASUREBOX_URL,
    liveServerUrl: process.env.REACT_APP_LIVE_SERVER_URL,
    liveTBoxServerUrl: process.env.REACT_APP_LIVE_TBOX_SERVER_URL,
    liveGamifServerUrl: process.env.REACT_APP_GAMIFICATION_SERVER_URL,
    watermarkStr: '/upload/w_60,g_south_east,x_10,y_10,l_waterm/',
    watermarkSetting: '/upload/w_60,g_south_east,x_10,y_10,l_waterm/',
    watermarkStrOne: '/upload/w_60,g_south_east,x_10,y_10,l_waterm/',
    watermarkStrTwo: '/upload/w_60,g_south_east,x_10,y_10,l_waterm/',

    socket: io(process.env.REACT_APP_SERVER_URL, {
        cors: {
            origin: process.env.REACT_APP_SERVER_URL,
            credentials: true
        }, transports: ['websocket']
    }),
    socketGamification: io(process.env.REACT_APP_GAMIFICATION_SERVER_URL, {
        cors: {
            origin: process.env.REACT_APP_GAMIFICATION_SERVER_URL,
            credentials: true
        }, transports: ['websocket']
    }),
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_access_token: JSON.parse(localStorage.getItem("userToken")),
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
    media_url: process.env.REACT_APP_MEDIA_URL,
    saveItem: function (name, value) { 
        localStorage.setItem(`${name}`, JSON.stringify(value));
    },
    removeItem: function (name) {
        localStorage.removeItem(name);
    },
    encryptUserData: function (data, token) {
        if (data) {
            localStorage.setItem('userInfo', JSON.stringify(data));
        }
        if (token) {
            localStorage.setItem('uToken', JSON.stringify(token));
        }
        return true;
    },
    decryptRes: function (userData) {
        let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
        let originalData = bytes.toString(CryptoJS.enc.Utf8);
        originalData = JSON.parse(originalData);

        return originalData;
    },
    encryptPayload: function (userData) {
        let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(userData), dataEncryptionKey).toString();
        return encryptedUser;
    },
    encryptToken: function (token) {
        let encryptedToken = CryptoJS.AES.encrypt(JSON.stringify({ token }), dataEncryptionKey).toString();
        return encryptedToken;
    },
    getUserKey: function (keys = null) {
        let userData = localStorage.getItem(keys);
        return userData;
    }, getToken: function () {
        let userData = localStorage.getItem('uToken');
        if (userData) {
            return userData;
        }
        return {};
    },
    logout: function () {
        localStorage.setItem("logout", true);
        localStorage.setItem("firstVisit", false);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem("twitter_user_secret")
        localStorage.removeItem("twitter_user_token")
        localStorage.removeItem('WalletAddress');
        window.location.href = "/login"
    }
}