export const REGISTER = "REGISTER"
export const GOT_RES = "GOT_RES"

export const GET_STATUS = 'GET_STATUS'
export const EDIT_STATUS = 'EDIT_STATUS';

// LOADER types
export const SET_LOADER = 'SET_LOADER';

// treasure box 

export const POST_CONTENT = 'POST_CONTENT'
export const POST_QUIZ = 'POST_QUIZ'
export const GET_CATAGORIES = 'GET_CATAGORIES'
export const GET_TAGS = 'GET_TAGS'
export const RECENT_IMG_UPLOAD = 'RECENT_IMG_UPLOAD'
export const SET_POST = 'SET_POST'
export const POST_UPDATED = 'POST_UPDATED'
export const GET_TREASUREBOXES = 'GET_TREASUREBOXES'
export const GET_TREASUREBOXES_COUNT = 'GET_TREASUREBOXES_COUNT'
export const UPDATE_TREASUREBOXVIEWS = 'UPDATE_TREASUREBOXVIEWS'
export const ANSWERS_UPDATED = 'ANSWERS_UPDATED'
export const SET_COMMENTS = 'SET_COMMENTS'
export const SET_TRENDING_POSTS = 'SET_TRENDING_POSTS'
export const CLEAR_TREASUREBOX_DATA = 'CLEAR_TREASUREBOX_DATA'
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO'
export const PROF_DATA_COUNT = 'PROF_DATA_COUNT'
export const COMMENT_UPDATED = 'COMMENT_UPDATED'
export const COMMENT_REPLACED = 'COMMENT_REPLACED'
export const SET_RELATED_POSTS = 'SET_RELATED_POSTS'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const GET_USER_POSTS = 'GET_USER_POSTS'
export const UPDATE_USER_POST_REACTION = 'UPDATE_USER_POST_REACTION'
export const GET_TRENDING_POSTS = 'GET_TRENDING_POSTS'
export const SET_UNSEENEVENTS = 'SET_UNSEENEVENTS'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const POST_DELETED = 'POST_DELETED'
export const POST_READ = 'POST_READ'
export const GET_USER_POSTS_LIST = 'GET_USER_POSTS_LIST'
export const CLEAR_USER_POST_DATA = 'CLEAR_USER_POST_DATA'
export const SET_SEARCH_POSTS = 'SET_SEARCH_POSTS'
export const UPDATE_TAGS = 'UPDATE_TAGS'
export const DELETED_POST = 'DELETED_POST'
export const SET_BOOSTED_POSTS = 'SET_BOOSTED_POSTS'
export const GET_USER_SUBMISSIONS = 'GET_USER_SUBMISSIONS'
export const USER_WALL_INFO = 'USER_WALL_INFO'
export const USER_CARD_INFO = 'USER_CARD_INFO'
export const CARD_DETAILS = 'USER_CARD_INFO'
export const CLEAR_YOURWALL_DATA = 'CLEAR_YOURWALL_DATA'
export const WALLET_PROVIDER = "WALLET_PROVIDER"
export const LIKES_UPDATED = "LIKES_UPDATED"
export const WALLET_LOADER = "WALLET_LOADER"
export const GET_BOOSTED_POSTS = "GET_BOOSTED_POSTS"
export const CLEAR_BOOSTED_DATA = "CLEAR_BOOSTED_DATA"
export const NFT_SOLD_LOADER = "NFT_SOLD_LOADER"
export const NFT_CREATED = "NFT_CREATED"
export const GET_STATUS_VIEW = "GET_STATUS_VIEW"
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_TASKS = "GET_USER_TASKS"
// export const GET_USER_PROFILE_POSTS = "GET_USER_PROFILE_POSTS"



// events creating of task (rough work)

export const POST_EVENT = 'POST_EVENT'

