import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'
import userReducer from "./user/userReducer";
import treasureBox from './treasureBox/reducer'
import walletReducer from "./walletProviders/walletReducer";

const middleware = [thunk]
const reducer = combineReducers({
  userData: userReducer,
  treasureBoxData : treasureBox,
  walletProvider : walletReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store