import React from 'react'
import '../loader/full-page-loader.css';

function FallbackLoader() {
  return (
    <React.Fragment>
        <div className="main-loader">
          <img className={'main-loader-img'} src="/images/logo.svg" alt="" />
        </div>
    </React.Fragment>
  )
}

export default FallbackLoader