import React from "react";
import { Route, Redirect } from "react-router-dom";

const Login = ({ Children, ...rest }, props) => {
	const authUser = () => {
		return localStorage.getItem("WalletAddress") && localStorage.getItem("token")
	}

	return (
		<Route
			{...rest}
			render={({ location }) =>
				authUser() ? (
					(<Children  {...props} />)
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}

export default Login;
