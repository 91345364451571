import {lazy} from 'react';
import UserProfile from './components/Profile/UserProfile';
import Layout8 from './layouts/layout_8';

const Layout1 = lazy(() => import("./layouts/layout_1"));
const Layout2 = lazy(() => import("./layouts/layout_2"));
const Layout3 = lazy(() => import("./layouts/layout_3"));
const Layout4 = lazy(() => import("./layouts/layout_4"));
const Layout5 = lazy(() => import("./layouts/layout_5"));
const Layout7 = lazy(() => import("./layouts/layout_7"));

const Settings = lazy(() => import("./components/Settings/Settings.js"));
const Activity = lazy(() => import("./components/Activity/Activity.js"));
const Leaderboard = lazy(() => import("./components/Leaderboard/Leaderboard"));
const Board = lazy(() => import("./components/Leaderboard/Board"));
const Achievements = lazy(() => import("./components/Achievements/Achievements"));
const Submissions = lazy(() => import("./components/Submissions/Submissions.js"));
const ConnectWallet = lazy(() => import("./connectWallet/connect.js"));
const CreateGame = lazy(() => import("./components/creategame/creategame"));
const EditPost = lazy(() => import("./components/creategame/editGame"));
const Format = lazy(() => import("./components/format/format"));
const Posts = lazy(() => import("./components/posts/posts"));
const Post = lazy(() => import("./components/posts/post/post"));
const PageNotFound = lazy(() => import("./components/routeNotFound/PageNotFound"));
const TreasureBox = lazy(() => import("./components/treasureBox"));
const PrivacyPolicy = lazy(() => import("./components/treasureBox/privacyPolicy"));
const TrendingPost = lazy(() => import("./components/posts/post/trendingPost"));
const TermsCondition = lazy(() => import("./components/treasureBox/termsCondition"));
const Notifications = lazy(() => import("./components/Notifications/notifications"));
const YoutubeShared = lazy(() => import("./components/YoutubeShared"));
const TwitterShared = lazy(() => import("./components/TwitterShared"));
const YourWall = lazy(() => import("./components/your-wall"));
const BoostedPosts = lazy(() => import("./components/boosted-posts"));
const Referral = lazy(() => import("./components/Referral/Referral"));
const LoginCopy = lazy(() => import("./components/Login/LoginCopy"));

const routes = [
    {
        path: "/login",
        layout: Layout7,
        access: true,
        exact: true,
        component: LoginCopy,
    },
    {
        path: "/youtube-connected",
        layout: Layout5,
        access: false,
        exact: true,
        component: YoutubeShared,
    },
    {
        path: "/twitter-connected",
        layout: Layout5,
        access: false,
        exact: true,
        component: TwitterShared,
    },
    {
        path: "/format",
        layout: Layout2,
        access: true,
        exact: true,
        component: Format,
    }, {
        path: "/board",
        layout: Layout2,
        access: true,
        exact: true,
        component: Board,
    },
    {
        path: "/notifications-all",
        layout: Layout2,
        access: false,
        exact: true,
        component: Notifications,
    },

    {
        path: "/settings/profile",
        layout: Layout1,
        access: false,
        exact: true,
        component: Settings,
    },

    {
        path: "/user-profile/:profile_address",
        layout: Layout8,
        access: true,
        exact: true,
        component: UserProfile,
    },

    {
        path: "/settings/activity",
        layout: Layout1,
        access: false,
        exact: true,
        component: Activity,
    },
    {
        path: "/trending",
        layout: Layout1,
        access: true,
        exact: true,
        component: TrendingPost,
    },
    {
        path: "/settings/submissions",
        layout: Layout1,
        access: false,
        exact: true,
        component: Submissions,
    },
    {
        path: "/settings/achievements",
        layout: Layout1,
        access: false,
        exact: true,
        component: Achievements,
    },
    {
        path: "/settings/leaderboard",
        layout: Layout1,
        access: false,
        exact: true,
        component: Leaderboard,
    },
    {
        path: "/settings/referral",
        layout: Layout1,
        access: false,
        exact: true,
        component: Referral,
    },
    {
        path: "/privacy-policy",
        layout: Layout1,
        access: true,
        exact: true,
        component: PrivacyPolicy,
    },
    {
        path: "/terms-and-conditions",
        layout: Layout1,
        access: true,
        exact: true,
        component: TermsCondition,
    },
    {
        path: "/create-game",
        layout: Layout2,
        access: false,
        exact: true,
        component: CreateGame,
    },
    {
        path: "/post",
        layout: Layout2,
        access: true,
        exact: true,
        component: EditPost,
    },
    {
        path: "/connect",
        layout: Layout2,
        access: true,
        exact: true,
        component: ConnectWallet,
    },
    {
        path: "/invite/:code",
        layout: Layout7,
        access: true,
        exact: true,
        component: LoginCopy,
    },
    {
        path: "/treasurebox",
        layout: Layout3,
        access: true,
        exact: true,
        component: Posts,
    },
    {
        path: "/post-view/:id",
        layout: Layout4,
        access: true,
        exact: true,
        component: Post,
    },
    {
        path: "/",
        layout: Layout5,
        access: true,
        exact: true,
        component: TreasureBox,
    },
    {
        path: "/your-wall",
        layout: Layout5,
        access: false,
        exact: true,
        component: YourWall,
    },
    {
        path: "/boosted-posts",
        layout: Layout5,
        access: true,
        exact: true,
        component: BoostedPosts,
    },
    {
        path: "*",
        layout: Layout4,
        access: true,
        exact: true,
        component: PageNotFound,
    },


];

export default routes;