import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';
import React, { lazy, Suspense } from "react"
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes'
import { PrivateRoute } from './privateRoute';
import FallbackLoader from './components/FallBackLoader/FallbackLoader';
import ErrorBoundary from "./ErrorBoundary";
const Notifications = lazy(() => import('react-notify-toast'));
const AppProvider = lazy(() => import('./provider/app'));
const renderRouteComponent = (route) => (props) => (
  <route.layout {...props}>
    <route.component {...props} />
  </route.layout>
);
function App() {
  console.log(window.navigator.onLine)
  localStorage.setItem("logout", true);
  return (
    <div className="wrapper">
      <ErrorBoundary>
        <Suspense fallback={<FallbackLoader />} >
          <Notifications />
          <AppProvider>
            <Router >
              <Switch>
                {
                  routes.map((route, index) => {
                    return (
                      <PrivateRoute
                      key={route}
                      path={route.path}
                      exact={route.exact}
                      access={route.access}
                      component={renderRouteComponent(route)}
                      />
                    )
                  })
                }
              </Switch>
            </Router>
          </AppProvider>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
