import {
    GOT_RES,CARD_DETAILS, SET_BOOSTED_POSTS, SET_SEARCH_POSTS, GET_TRENDING_POSTS, SET_RELATED_POSTS, COMMENT_UPDATED, COMMENT_REPLACED, CLEAR_TREASUREBOX_DATA,
    GET_TREASUREBOXES_COUNT, SET_LOADER, SET_TRENDING_POSTS, SET_COMMENTS, ANSWERS_UPDATED, LIKES_UPDATED, UPLOAD_VIDEO,
    SET_POST, POST_CONTENT, GET_CATAGORIES, GET_TAGS, RECENT_IMG_UPLOAD, GET_TREASUREBOXES, UPDATE_TREASUREBOXVIEWS, GET_USER_POSTS,
    UPDATE_USER_POST_REACTION, UPDATE_TAGS, GET_USER_SUBMISSIONS, CLEAR_YOURWALL_DATA, GET_BOOSTED_POSTS, CLEAR_BOOSTED_DATA
} from "../actionTypes";
import { apiHelper, gamificationApiHelper } from "../apiHelper";
import { toast } from "react-toastify";
import { ENV } from "../../config/config";

export const postTreasureBox = (data) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/upload/tbox`, data);

        if (res.data) {
            dispatch({
                type: POST_CONTENT,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}



export const createPersonality = (data) => async (dispatch) => {
    try {
        const plainObject = Object.fromEntries(data.formData.entries())
        let userTasks = localStorage.getItem("userTasks") ? JSON.parse(localStorage.getItem("userTasks")) : [];
        const event = userTasks.find(event => event.eventType === "creating");
        plainObject.points = event?.points
        plainObject.pointType = event?.pointType
        plainObject.pointEventName = event?.name
        plainObject.pointEventId = event?._id
        let enc = ENV.encryptPayload(plainObject)
        let data_enc = {
            enc: enc
        }
        let res = await apiHelper("post", `/v1/treasurebox/upload/tbox/${data.type}`, data_enc);
        if (res.data) {
            return res.data.TreasureBox
        }

    } catch (error) {
        console.log(error.message);
    }
}
export const uploadVideo = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/treasurebox/upload/video`, data);
        if (res.data) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            dispatch({
                type: UPLOAD_VIDEO,
                payload: res.data.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
        dispatch()
    } catch (error) {
        console.log(error.message);
    }
}
export const updatePersonality = (data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/upload/tbox/${data.type}`, data.formData);
        if (res.data) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getCategories = (data, type) => async (dispatch) => {
    try {

        let res = await gamificationApiHelper("get", `/v1/category?enabled=true`, "");
        if (res.data) {
            dispatch({
                type: GET_CATAGORIES,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getTags = (data, type) => async (dispatch) => {
    try {

        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await gamificationApiHelper("get", `/v1/tags?enabled=true`, "");
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        if (res.data) {
            dispatch({
                type: GET_TAGS,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const multipart = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/media`, data);
        if (res.data) {
            dispatch({
                type: RECENT_IMG_UPLOAD,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getList = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let query = '';

        if (data?.category && data.category.length > 0) {
          query += `category=${data.category}&`;
        }
        if (data?.temType && data.temType.length > 0) {
          query += `tempType=${data.temType}&`;
        }
        if (data?.search !== undefined && data.search && data.search.length > 0) {
          query += `post_find=${data.search}&`;
        }
        query += `offset=${data.offset}&limit=${data.limit}&`;
        if (data?.tag && data.tag.length > 0) {
          query += `tag=${data.tag}&`;
        }
        if (data?.userId && data.userId.length > 0) {
          query += `userId=${data.userId}`;
        }
        
        let url = `/v1/treasurebox/?${query}`;
        
        let res = await apiHelper("get", url, "");
        if (res.data) {
            dispatch({
                type: GET_TREASUREBOXES,
                payload: res.data.TreasureBox
            })
            dispatch({
                type: GET_TREASUREBOXES_COUNT,
                payload: res.data.TreasureBoxCount
            })
            dispatch({
                type: SET_TRENDING_POSTS,
                payload: res.data.trendingPosts

            })
            dispatch({
                type: SET_BOOSTED_POSTS,
                payload: res.data.boostedBoxData

            })
            dispatch({
                type: SET_SEARCH_POSTS,
                payload: data.search
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const getTrendingList = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/trendings/?category=${data.category}&tempType=${data.temType}&tag=${data.tag}&search=${data.search}`, "");
        if (res.data) {
            dispatch({
                type: GET_TRENDING_POSTS,
                payload: res.data.trendingPosts
            })
            dispatch({
                type: SET_SEARCH_POSTS,
                payload: data.search
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const getBoostedList = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/boosted/?tempType=${data.temType}&tag=${data.tag}&post_find=${data.search}&offset=${data.offset}&limit=${data.limit}`, "");
        if (res.data) {
            dispatch({
                type: GET_BOOSTED_POSTS,
                payload: res.data.boostedPosts
            })
            dispatch({
                type: SET_SEARCH_POSTS,
                payload: data.search
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const multipartStateless = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/media`, data);
        if (res.data) {
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const updateTreasureBoxLikes = (id, data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/rate/${id}`, data);
        if (res.data) {
            dispatch({
                type: LIKES_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const getSinglePost = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/post/${data.postId}?templateType=${data.type}`, "")

        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_POST,
                    payload: data.TreasureBox
                })
                dispatch({
                    type: SET_COMMENTS,
                    payload: data.comments
                })
                dispatch({
                    type: SET_TRENDING_POSTS,
                    payload: data.trendingPosts
                })
                dispatch({
                    type: SET_RELATED_POSTS,
                    payload: data.relatedPosts
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }

        }

    } catch (error) {
        console.log(error)
    }
}

export const updateTreasureBoxViews = (id, data) => async (dispatch) => {
    try {
        let userTasks = localStorage.getItem("userTasks") ? JSON.parse(localStorage.getItem("userTasks")) : [];
        const event = userTasks.find(event => event.eventType === "view_post");
        data.points = event?.points
        data.pointType = event?.pointType
        data.pointEventName = event?.name
        data.pointEventId = event?._id
        let enc = ENV.encryptPayload(data);
        let data_enc = {
            enc: enc
        }
        let res = await apiHelper("put", `/v1/treasurebox/view/${id}`, data_enc);
        if (res.data) {
            dispatch({
                type: UPDATE_TREASUREBOXVIEWS,
                payload: res.data.views
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const updateTreasureBoxAnswers = (id, data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/answer/${id}`, data);
        if (res.data) {
            dispatch({
                type: ANSWERS_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const clearTreasureboxData = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_TREASUREBOX_DATA
        })
    } catch (error) {
        console.log(error.message);
    }
}
export const clearYourWallData = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_YOURWALL_DATA
        })
    } catch (error) {
        console.log(error.message);
    }
}
export const clearBoostedData = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_BOOSTED_DATA
        })
    } catch (error) {
        console.log(error.message);
    }
}

export const postTreasureBoxComment = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/treasurebox/comment/${id}`, data);
        if (res.data) {
            dispatch({
                type: COMMENT_UPDATED
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message)
    }
}

export const updateTreasureBoxComment = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("put", `/v1/treasurebox/update/comment/${id}`, data);
        if (res.data) {
            dispatch({
                type: COMMENT_REPLACED
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message)
    }
}

export const getUserPosts = (wallet) => async (dispatch) => {
    try {
        if(ENV.getUserKey("WalletAddress")){
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            let res = await apiHelper("get", `/v1/treasurebox/profile-info/${wallet}`, "");

            if (res.data) {
                dispatch({
                    type: GET_USER_POSTS,
                    payload: res.data
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                return res;
            }
            else {
                toast.error("Error in response");
            }
        }
    } catch (error) {
        console.log(error.message)
    }
}

export const updatePostReactions = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("put", `/v1/treasurebox/react/${id}`, data);

        if (res.data) {
            dispatch({
                type: UPDATE_USER_POST_REACTION,
                payload: res.data.treasureBoxData
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message)
    }
}

export const updateTags = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await gamificationApiHelper("post", `/v1/tags/add`, data);

        if (res.data) {
            dispatch({
                type: UPDATE_TAGS,
                payload: res.data.tags
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const getUserSubmissions = (data) => async (dispatch) => {
    try {
        dispatch({
            type: GOT_RES,
            payload: false
        })
        
        let res = await apiHelper("get", `/v1/treasurebox/submissions/${data.address}?type=${data.type}&tag=${data.tag}`, "");

        if (res.data) {
            dispatch({
                type: GET_USER_SUBMISSIONS,
                payload: res.data.submissions
            })
            dispatch({
                type: GOT_RES,
                payload: true
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message)
    }
}
export const getPostDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/card/details?userId=${data.userId}&cardType=${data.cardType}&temType=${data.temType}&tag=${data.tag}&post_find=${data.search}`, "");

        if (res.data) {
            dispatch({
                type: CARD_DETAILS,
                payload: res.data.data
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message)
    }
}


