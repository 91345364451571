import React, { useEffect, useState } from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSearch, faBell, faPlus, faAngleRight, faClose, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { ENV } from "../../config/config"
import { clearUserData, getNotifications, getUserProfile, getUserTasks, readNotification, RegisterUser, getUserStatus } from '../../redux/user/action';
import { clearTreasureboxData, getList, getUserPosts, clearYourWallData, clearBoostedData, getBoostedList, getPostDetails } from '../../redux/treasureBox/action';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from "react-notify-toast";
import WelcomeModel from "./WelcomeModel"
import { useAccount, useDisconnect } from "wagmi";
import { getQueryParam } from '../../config/helper';
import Bars from "../../assets/images/bars.svg"
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import SideNavbar from '../sideNavbar/sideNavbar';
import MobileDetect from 'mobile-detect';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
const BACKEND_ADDR = process.env.REACT_APP_SERVER_URL;

let md = new MobileDetect(window.navigator.userAgent);
const { socket, socketGamification } = ENV
const instance = axios.create({
    withCredentials: true,
    baseURL: BACKEND_ADDR
})
const Header = () => {


    const [showModel, setShowModel] = useState(false);

    const handleClose = () => setShowModel(false);
    const handleShowModel = () => setShowModel(true);

    let show = localStorage.getItem("showDot");
    const [isActive, setActive] = useState(false);
    const [showDot, setShowDot] = useState(show ? show : false)
    const [searchShow, setSearchShow] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [searchBoostedInput, setSearchBoostedInput] = useState('');
    const [searchYourWallInput, setSearchYourWallInput] = useState('');
    const [searchUserProfileInput, setSearchUserProfileInput] = useState('');
    const [showConnect, setShowconnect] = useState(false);
    const [login, setLogin] = useState(false);
    const [showWelcomeModel, setWelocmeModel] = useState(true);
    const [seachLoader, setSeachLoader] = useState(false);
    const { address, isConnected } = useAccount();
    const { profile_address } = useParams();

    let WalletAddress = ENV.getUserKey("WalletAddress")
    const unseenEvents = useSelector(state => state.userData.notification?.unseenNotifCount)
    const notifications = useSelector(state => state.userData.notification?.notes)
    const user = useSelector(state => state.userData?.profile?.data)
    const postRead = useSelector(state => state.userData?.postRead)
    let history = useHistory()
    const { disconnect } = useDisconnect()
    const location = useLocation()
    const dispatch = useDispatch();
    let userTasks = useSelector(state => state.userData?.tasks)
    if (userTasks && userTasks.length > 0) {
        localStorage.setItem("userTasks", JSON.stringify(userTasks));
    }

    let code = getQueryParam(location, 'inviteCode')
    useEffect(() => {
        dispatch(getNotifications(WalletAddress))
    }, [WalletAddress, postRead, user])

    useEffect(() => {
        socket.on("tBox_created", (data) => {
            setShowDot(true)
            localStorage.setItem("showDot", true)
            dispatch(getNotifications(WalletAddress))
            dispatch(getUserPosts(WalletAddress))
            dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
        })

        socket.on("tbox_liked", (data) => {
            let notData = "Post like event created successfully"
            if (data.address == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
                notify.show(notData, "success", 5000);
            }
        })
        socket.on("tbox_boosted_admin", (data) => {
            let notData = "Your post is boosted by admin for one hour!"
            if (data.userId == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
                notify.show(notData, "success", 5000);
            }
        })
        socket.on("social_followers", (data) => {
            let notData = `You got your ${data.socialPlatform} followers points`
            if (data.userId == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                notify.show(notData, "success", 5000);
            }
        })

        socket.on("referring", (data) => {
            let notData = "Someone registered with your referral link"

            if (data.address == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
                notify.show(notData, "Success", 5000);
            }
        })

        socket.on("tBox_shared", (data) => {
            let notData = ''
            dispatch(getNotifications(WalletAddress))
            if (data.success === true) {
                localStorage.setItem("showDot", true)
                setShowDot(true)
                notData = `${data.eventName + " event created successfully"}`
            } else {
                notData = "You had already shared this post!"
            }
            if (data.userId == WalletAddress) {
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
                notify.show(notData, "Success", 5000);
            }
        })
        socket.on("tBox_viewed", (data) => {
            let notData = "Post view event created successfully"
            if (data.address == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
                notify.show(notData, "Success", 5000);
            }
        })
        socket.on("sign_up", (data) => {
            if (data.address == WalletAddress) {
                setShowDot(true)
                localStorage.setItem("showDot", true)
                dispatch(getNotifications(WalletAddress))
                dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
            }
        })
        socketGamification.on("tbox_tasks_updated", (data) => {
            setShowDot(true)
            localStorage.setItem("showDot", true)
            dispatch(getUserTasks());
        })
    }, [socket, socketGamification])
    const handleNotification = () => {
        localStorage.setItem("showDot", false)
        setShowDot(false)
        setActive(!isActive);
    }
    useEffect(() => {
        dispatch(getNotifications(WalletAddress))
        dispatch(getUserPosts(WalletAddress))
        metamaskEventDetector()
        if (WalletAddress) {
            dispatch(getUserStatus({ address: WalletAddress, platform: "Treasurebox" }))
        }
    }, [WalletAddress])

    useEffect(() => {
        if (WalletAddress && user != undefined && (user?.name == undefined || user?.name == "" || user?.email == undefined || user?.email == "")) {
            history.push(`/settings/profile?signup=${user?.name && user?.email ? false : true}`)
        }
    }, [user])

    const metamaskEventDetector = async () => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                logout()
            })
            window.ethereum.on('disconnect', () => {
                logout()
            })
        }
    }

    useEffect(async () => {
        if (address && isConnected && !localStorage.getItem("logout")) {
            await Register(address);
            setLogin(false);
            setShowconnect(false);
        }
        if (address && isConnected && login) {
            await Register(address);
            setLogin(false);
            setShowconnect(false);

        }
    }, [address, isConnected, login]);


    const Register = async (address) => {
        const data = {
            address: address,
            inviteeCode: code ? code : ""
        }
        dispatch(RegisterUser(data, history))
    }
    const handleClickScroll = () => {
        let element = document.getElementById('searchPosts');

        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'auto' });
        }

    };
    const handleSearch = () => {
        if (searchShow === true && searchInput.length > 0) {
            handleSearchPost()
        }
        else {
            setSearchShow(!searchShow);
        }
    }

    const handleRead = (id) => {
        dispatch(readNotification(id))
    }

    const handleYourWallSearch = () => {
        if (searchShow === true && searchYourWallInput.length > 0) {
            handleSearchYourWall()
        }
        else {
            setSearchShow(!searchShow);
        }
    }

    const handleBoostedSearch = () => {
        if (searchShow === true && searchBoostedInput.length > 0) {
            handleSearchBoosted()
        }
        else {
            setSearchShow(!searchShow);
        }
    }

    const handleUserProfileSearch = () => {
        if (searchShow === true && searchUserProfileInput.length > 0) {
            handleSearchUserProfile()
        }
        else {
            setSearchShow(!searchShow);
        }
    }

    const logout = () => {
        localStorage.setItem("logout", true);
        localStorage.setItem("firstVisit", false);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('WalletAddress');
        disconnect()
        setWelocmeModel(false);
        history.push('/');
    }

    const fetchposts = async (searchPost) => {
        let data = { category: "", tag: '', temType: "", search: searchPost, offset: 0, limit: '' }
        await dispatch(getList(data))
        handleClickScroll()
        setSeachLoader(false)
    }
    const fetchBoostedPosts = async (searchPost) => {
        setSeachLoader(true)
        let data = { search: searchPost, tag: "", temType: "", offset: 0, limit: '' }
        await dispatch(getBoostedList(data))
        setSeachLoader(false)
    }
    const fetchYourWall = async (searchPost) => {
        setSeachLoader(true)
        let data = { cardType: "submission", userId: WalletAddress, type: '', offset: 0, limit: 21, category: "", tag: '', temType: "", search: searchPost }
        dispatch(getPostDetails(data))
        setSeachLoader(false)
    }
    const fetchUserProfilePosts = async (searchPost) => {
        setSeachLoader(true)
        let data = { category: "", temType: "", tag: '', search: searchPost, offset: 0, limit: 10, address: profile_address, platform: "Treasurebox", sort: "" }
        dispatch(getUserProfile(data))
        setSeachLoader(false)
    }

    const handleSearchPost = () => {
        if (searchInput) {
            dispatch(clearTreasureboxData());
            fetchposts(searchInput);
        }
    }

    const handleSearchYourWall = () => {
        if (searchYourWallInput) {
            dispatch(clearYourWallData());
            fetchYourWall(searchYourWallInput);
        }
    }

    const handleSearchBoosted = () => {
        if (searchBoostedInput) {
            dispatch(clearBoostedData());
            fetchBoostedPosts(searchBoostedInput);
        }
    }

    const handleSearchUserProfile = () => {
        if (searchUserProfileInput) {
            dispatch(clearUserData());
            fetchUserProfilePosts(searchUserProfileInput);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchInput) {
                dispatch(clearTreasureboxData());
                fetchposts(searchInput);
            }
        }
    }

    const handleYourWallKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchYourWallInput) {
                dispatch(clearYourWallData());
                fetchYourWall(searchYourWallInput);
            }
        }
    }

    const handleBoostedKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchBoostedInput) {
                dispatch(clearBoostedData());
                fetchBoostedPosts(searchBoostedInput);
            }
        }
    }

    const handleUserProfileKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchUserProfileInput) {
                dispatch(clearUserData());
                fetchUserProfilePosts(searchUserProfileInput);
            }
        }
    }

    const handleResetPost = async () => {
        dispatch(clearTreasureboxData());
        let data = { category: "", tag: '', temType: "", search: '', offset: 0, limit: 21 }
        setSearchInput('')
        await dispatch(getList(data))
    }

    const handleResetYourWall = () => {
        dispatch(clearYourWallData());
        let data = { cardType: "submission", userId: WalletAddress, type: '', offset: 0, limit: 21, category: "", tag: '', temType: "", search: '' }
        setSearchYourWallInput('')
        dispatch(getPostDetails(data))
    }

    const handleResetBoosted = () => {
        dispatch(clearBoostedData());
        let data = { userId: WalletAddress, offset: 0, limit: 21, tag: '', temType: "", search: '' }
        setSearchBoostedInput('')
        dispatch(getBoostedList(data))
    }

    const handleResetUserProfile = () => {
        dispatch(clearUserData());
        let data = { category: "", temType: "", tag: '', search: '', offset: 0, limit: 10, address: profile_address, platform: "Treasurebox", sort: "" }
        setSearchUserProfileInput('')
        dispatch(getUserProfile(data))
    }

    const resize = () => {
        if ($(window).width() < 680) {
            $('.sd-root-modern').removeClass('sd-root-modern--mobile');
        }
    }
    function checkPosition() {
        if (window.matchMedia('(max-width: 767px)').matches) {
            $('.sd-root-modern').removeClass('sd-root-modern--mobile');
        }
    }
    function getAllEvents(element) {
        let result = [];
        for (let key in element) {
            if (key.indexOf('on') === 0) {
                result.push(key.slice(2));
            }
        }
        return result.join(' ');
    }
    let el = $('.container');
    el.bind(getAllEvents(el[0]), function (e) {
        if ($(window).width() < 680) {
            $('.sd-root-modern').removeClass('sd-root-modern--mobile');
        }
    });

    document.addEventListener('click', function (event) {
        resize()
        checkPosition()
        const searchForm = document.getElementsByName('searchbtn')
        if (searchShow === true && event.target !== searchForm[0] && event.target !== searchForm[1] && event.target !== searchForm[2] && event.target !== searchForm[3] && event.target !== searchForm[4] && event.target !== searchForm[5] && event.target !== searchForm[5].childNodes[0]) {
            setSearchShow(false);
            setSearchInput('')
            setSearchYourWallInput('')
            setSearchBoostedInput('')
        }
    });

    $(document).mouseup(function (e) {
        let container = $('.notify-box');
        let containerIcon = $('.profile-notifications-main');

        if (!container.is(e.target) && container.has(e.target).length === 0 && !containerIcon.is(e.target) && containerIcon.has(e.target).length === 0) {
            setActive(false);
        }
    });

    const handleChange = (event) => {
        setSearchInput(event.target.value)
    }

    const handleBoostedChange = (event) => {
        setSearchBoostedInput(event.target.value)
    }

    const handleYourWallChange = (event) => {
        setSearchYourWallInput(event.target.value)
    }

    const handleUserProfileChange = (event) => {
        setSearchUserProfileInput(event.target.value)
    }

    const blacklist = [
        'points for the registration',
        'points for sharing',
        'points for creating',
        'Viewed your post,',
        'Shared your post'
    ]
    const regex = new RegExp(blacklist.join('|'))

    return (
        <>
            {!WalletAddress &&
                showWelcomeModel &&
                location.pathname === "/" &&
                <WelcomeModel setWelocmeModel={setWelocmeModel} />}

            {showConnect && history.push('/login')}

            <header className='treasurebox-header'>
                <Container fluid>
                    <Navbar expand="lg">
                        <Link className="main-logo" to={`/`}>
                            <Navbar.Brand className='d-flex align-items-center'>
                                <div className="image">
                                    {md.mobile()
                                        ? <img height={30} width={30} loading='lazy' src={`/images/logo.svg`} alt="" className="img-fluid" />
                                        : <img height={50} width={50} loading='lazy' src={`/images/logo.svg`} alt="" className="img-fluid" />

                                    }                                </div>MYNTIST
                            </Navbar.Brand>
                        </Link>

                        <div className='d-flex order-3'>
                            {location.pathname == '/' ?
                                <div className='search-login-btn'>
                                    <div name='searchbtn' className='d-flex align-items-center'>
                                        <input type="text" id="site-search" name='searchbtn' placeholder='Search...' className={searchShow ? "input-width transition" : "transition input-no-width"} onKeyDown={handleKeyDown} value={searchInput} onChange={handleChange} />
                                        <Button name='searchbtn' className={searchShow ? "d-block refresh-search" : "d-none search-btn-block"} onClick={handleResetPost}><FontAwesomeIcon icon={faRefresh} name="searchbtn" /></Button>
                                    </div>
                                    <Button name='searchbtn' className={searchShow ? "search-icon main-btn-style active" : "main-btn-style search-icon"} disabled={seachLoader} onClick={() => handleSearch()}>
                                        <FontAwesomeIcon icon={faSearch} name="searchbtn" />
                                    </Button>
                                </div>
                                : ''}
                            {location.pathname == '/your-wall' ? <div name='searchbtn' className='search-login-btn d-flex align-items-center'>
                                <input type="text" id="site-search" name='searchbtn' placeholder='Search...' className={searchShow ? "input-width transition" : "transition input-no-width"} onKeyDown={handleYourWallKeyDown} value={searchYourWallInput} onChange={handleYourWallChange} />
                                <Button name='searchbtn' className={searchShow ? "d-block refresh-search" : "d-none search-btn-block"} onClick={handleResetYourWall}><FontAwesomeIcon icon={faRefresh} name="searchbtn" /></Button>
                                <Button name='searchbtn' className={searchShow ? "search-icon main-btn-style active" : "main-btn-style search-icon"} disabled={seachLoader} onClick={() => handleYourWallSearch()}>
                                    <FontAwesomeIcon icon={faSearch} name="searchbtn" />
                                </Button>
                            </div> : ''}
                            {location.pathname == '/boosted-posts' ? <div name='searchbtn' className='search-login-btn d-flex align-items-center'>
                                <input type="text" id="site-search" name='searchbtn' placeholder='Search...' className={searchShow ? "input-width transition" : "transition input-no-width"} onKeyDown={handleBoostedKeyDown} value={searchBoostedInput} onChange={handleBoostedChange} />
                                <Button name='searchbtn' className={searchShow ? "d-block refresh-search" : "d-none search-btn-block"} onClick={handleResetBoosted}><FontAwesomeIcon icon={faRefresh} name="searchbtn" /></Button>
                                <Button name='searchbtn' className={searchShow ? "search-icon main-btn-style active" : "main-btn-style search-icon"} disabled={seachLoader} onClick={() => handleBoostedSearch()}>
                                    <FontAwesomeIcon icon={faSearch} name="searchbtn" />
                                </Button>
                            </div> : ''}
                            {location.pathname.includes("/user-profile") ? <div name='searchbtn' className='search-login-btn d-flex align-items-center'>
                                <input type="text" id="site-search" name='searchbtn' placeholder='Search...' className={searchShow ? "input-width transition" : "transition input-no-width"} onKeyDown={handleUserProfileKeyDown} value={searchUserProfileInput} onChange={handleUserProfileChange} />
                                <Button name='searchbtn' className={searchShow ? "d-block refresh-search" : "d-none search-btn-block"} onClick={handleResetUserProfile}><FontAwesomeIcon icon={faRefresh} name="searchbtn" /></Button>
                                <Button name='searchbtn' className={searchShow ? "search-icon main-btn-style active" : "main-btn-style search-icon"} disabled={seachLoader} onClick={() => handleUserProfileSearch()}>
                                    <FontAwesomeIcon icon={faSearch} name="searchbtn" />
                                </Button>
                            </div> : ''}

                            {
                                WalletAddress ?
                                    <>
                                        <Link className={location.pathname == '/format' || location.pathname == '/create-game' ? "main-btn-style ms-2 nav-btn is-active" : "main-btn-style ms-2 nav-btn"} to={`/format`}><span className='d-lg-block d-none'>Add Post</span><span className='d-lg-none d-block'><FontAwesomeIcon icon={faPlus} name="searchbtn" /></span></Link>
                                    </>
                                    :
                                    <>
                                        <Link className={location.pathname == '/format' || location.pathname == '/create-game' ? "main-btn-style ms-2 nav-btn is-active" : "main-btn-style ms-2 nav-btn"} to={`/format`}><span className='d-lg-block d-none'>Add Post</span><span className='d-lg-none d-block'><FontAwesomeIcon icon={faPlus} name="searchbtn" /></span></Link>
                                        <Link className="main-btn-style ms-2  nav-btn" to={'/login'}  ><span className='d-lg-block d-none'>Login</span><span className='d-lg-none d-block'><FontAwesomeIcon icon={faRightToBracket} name="searchbtn" /></span></Link>
                                    </>
                            }
                            {WalletAddress &&
                                <div className='profile-notifications-main d-lg-block'>
                                    <div className='profile-notifications position-relative'>
                                        <Button className={isActive || location.pathname == "/notifications-all" ? 'ms-2 is-active' : 'ms-2'} onClick={() => handleNotification()}>
                                            <FontAwesomeIcon icon={faBell} name="" />
                                            {showDot.toString().localeCompare("true") != -1 ?
                                                <span className='notifi-digit'>{unseenEvents ? unseenEvents[0]?.count : 0}</span>
                                                : ""
                                            }
                                        </Button>
                                        <div className='position-relative notify-box'>
                                            <div className={isActive ? "notification-detail d-block" : "d-none"}>
                                                <h5 className='text-center'>Notifications</h5>
                                                <ul>
                                                    {notifications && notifications?.length > 0 ?
                                                        notifications.length > 4 ?
                                                            notifications?.filter(notification => notification.isRead === false).slice(0, 4).map((post) => {
                                                                return (<li key={uuidv4()} className={!post?.isRead ? 'unread-notification' : ""}>
                                                                    <Link to={post?.postId ? `/post-view/${post?.postId}` : "/"} onClick={() => handleRead(post._id)}>
                                                                        {regex?.test(post.description) ?
                                                                            <p>Congratulations @<strong>{post?.user[0]?.name ? post.user[0]?.name : "Anoymous"}</strong>, {post.description}</p>
                                                                            : <p>@<strong>{post?.user[0]?.name ? post.user[0]?.name : "Anoymous"}</strong>, {post.description}</p>
                                                                        }

                                                                    </Link>
                                                                </li>)
                                                            }) :
                                                            notifications?.map((post) => {
                                                                return (<li key={uuidv4()} className={!post?.isRead ? 'unread-notification' : ""} >
                                                                    <Link to={post?.postId ? `/post-view/${post?.postId}` : "/"} onClick={() => handleRead(post._id)}>
                                                                        {regex?.test(post.description) ?
                                                                            <p >Congratulations @<strong>{post?.user[0]?.name ? post.user[0]?.name : "Anoymous"}</strong>, {post.description}</p>
                                                                            :
                                                                            <p >@<strong>{post?.user[0]?.name ? post.user[0]?.name : "Anoymous"}</strong>, {post.description}</p>
                                                                        }

                                                                    </Link>
                                                                </li>)
                                                            })
                                                        : <p className='p-2 text-center'>No notifications yet</p>}
                                                </ul>
                                                <Link className='text-center see-all-activities' to="/notifications-all">
                                                    <p>See all Notifications</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {WalletAddress ?
                                <div className='profile-notifications d-lg-block d-none'>
                                    <Link className="nav-link" to={`/settings/profile`}>
                                        <Button className={user && user?.picture?.length > 0 ? 'me-1 ms-2 p-0' : 'me-1 ms-2'}>
                                            {
                                                user && user?.picture?.length > 0 ?
                                                    <img src={`${user?.picture}`} alt="" className='img-fluid img-fixed' />

                                                    :
                                                    <FontAwesomeIcon icon={faUser} name="" />
                                            }
                                        </Button>
                                    </Link>
                                </div> : ''
                            }
                            <Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-2'>
                                <img loading='lazy' src={Bars} alt="toggle" className='img-fluid' />
                            </Navbar.Toggle>
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav" className=' justify-content-end'>
                            <Nav className={searchShow ? "d-none" : " transition ms-auto align-items-end"}>
                                <Link className={location.pathname == "/" ? 'nav-link me-2 is-active' : 'nav-link me-2'} to={`/`}>Treasurebox</Link>
                                <Link className={location.pathname == "/board" ? 'nav-link me-2 is-active' : 'nav-link me-2'} to={'/board'}>LEADERBOARD</Link>
                                {WalletAddress ? <Link className={location.pathname == "/your-wall" ? 'nav-link me-2 is-active' : 'nav-link me-2'} to={`/your-wall`}>Your Wall</Link> : ''}
                                {WalletAddress ? <span className="nav-link me-2 d-lg-none d-flex justify-content-between align-items-center" onClick={handleShowModel} /*to={`/settings/profile`} */ ><span>Profile</span><span><FontAwesomeIcon icon={faAngleRight} /></span></span> : ''}
                                <Modal className='profile-model' show={showModel} onHide={handleClose} fullscreen={true}>
                                    <button onClick={handleClose} className="close-btn">
                                        <FontAwesomeIcon icon={faClose} />
                                    </button>
                                    <Modal.Body>
                                        <SideNavbar />
                                    </Modal.Body>
                                </Modal>

                            </Nav>
                        </Navbar.Collapse>

                    </Navbar>
                </Container>
            </header >
        </>
    )
}

export default Header