import {
    GOT_RES, CARD_DETAILS, SET_BOOSTED_POSTS, SET_SEARCH_POSTS, GET_TRENDING_POSTS, SET_RELATED_POSTS, COMMENT_REPLACED, COMMENT_UPDATED, CLEAR_TREASUREBOX_DATA,
    GET_TREASUREBOXES_COUNT, SET_LOADER, SET_TRENDING_POSTS, SET_COMMENTS, ANSWERS_UPDATED, POST_UPDATED, UPLOAD_VIDEO,
    SET_POST, POST_CONTENT, GET_CATAGORIES, GET_TAGS, RECENT_IMG_UPLOAD, GET_TREASUREBOXES, UPDATE_TREASUREBOXVIEWS,
    GET_USER_POSTS, UPDATE_USER_POST_REACTION, UPDATE_TAGS, GET_USER_SUBMISSIONS, CLEAR_YOURWALL_DATA, LIKES_UPDATED, GET_BOOSTED_POSTS, CLEAR_BOOSTED_DATA
} from "../actionTypes";

const initialState = {
    content: [],
    catogories: [],
    tags: [],
    list: [],
    search: '',
    views: 0,
    gotSubmission: false,
    postUpdated: false,
    commentUpdated: false,
    commentreplaced: false,
    reactionsUpdated: false,
    likesUpdated: false,
    answersUpdated: false,
    comments: [],
    trendingPosts: [],
    boostedPosts: [],
    boostedBoxData: [],
    allTrendingPosts: [],
    submissions: [],
    loaderActive: false,
    deletedPost: false
}

const treasureBox = (state = initialState, action) => {

    switch (action.type) {
        case POST_CONTENT:
            return {
                ...state,
                content: action.payload
            }
        case GET_CATAGORIES:
            return {
                ...state,
                catogories: action.payload
            }
        case GET_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case RECENT_IMG_UPLOAD:
            return {
                ...state,
                rec_image_id: action.payload
            }
        case GET_TREASUREBOXES:
            return {
                ...state,
                list: state.list.concat(action.payload)
            }

        case GOT_RES:
            return {
                ...state,
                gotSubmission: action.payload
            }
        case GET_TREASUREBOXES_COUNT:
            return {
                ...state,
                treasureBoxCount: action.payload
            }
        case POST_UPDATED:
            return {
                ...state,
                postUpdated: !state.postUpdated,
            }
        case COMMENT_UPDATED:
            return {
                ...state,
                commentUpdated: !state.commentUpdated,
            }
        case COMMENT_REPLACED:
            return {
                ...state,
                commentreplaced: !state.commentreplaced,
            }
        case SET_POST:
            return {
                ...state,
                post: action.payload
            }
        case UPDATE_TREASUREBOXVIEWS:
            return {
                ...state,
                views: action.payload
            }
        case UPLOAD_VIDEO:
            return {
                ...state,
                file: action.payload
            }
        case ANSWERS_UPDATED:
            return {
                ...state,
                answersUpdated: !state.answersUpdated
            }
        case SET_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case SET_TRENDING_POSTS:
            return {
                ...state,
                trendingPosts: action.payload
            }
        case SET_BOOSTED_POSTS:
            return {
                ...state,
                boostedBoxData: action.payload
            }
        case GET_TRENDING_POSTS:
            return {
                ...state,
                allTrendingPosts: action.payload
            }
        case GET_BOOSTED_POSTS:
            return {
                ...state,
                boostedPosts: state.boostedPosts.concat(action.payload)
            }

        case CARD_DETAILS:
            return {
                ...state,
                postsDetail: action.payload
            }
        case SET_RELATED_POSTS:
            return {
                ...state,
                relatedPosts: action.payload
            }
        case GET_USER_POSTS:
            return {
                ...state,
                userPosts: action.payload,
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload

            }
        case SET_SEARCH_POSTS:
            return {
                ...state,
                search: action.payload,
            }

        case CLEAR_TREASUREBOX_DATA:
            return {
                tags: state.tags,
                content: [],
                catogories: state.catogories,
                list: [],
                search: '',
                views: 0,
                postUpdated: false,
                answersUpdated: false,
                comments: [],
                trendingPosts: [],
                boostedPosts: [],
                loaderActive: false,
                commentUpdated: false,
                commentreplaced: false,
                userPosts: state.userPosts
            }
        case CLEAR_YOURWALL_DATA:
            return {
                ...state,
                tags: state.tags,
                userPosts: []
            }
        case CLEAR_BOOSTED_DATA:
            return {
                ...state,
                tags: state.tags,
                boostedPosts: []
            }
        case UPDATE_USER_POST_REACTION:
            return {
                ...state,
                reactionsUpdated: !state.reactionsUpdated
            }
        case LIKES_UPDATED:
            return {
                ...state,
                likesUpdated: !state.likesUpdated
            }
        case UPDATE_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case GET_USER_SUBMISSIONS:
            return {
                ...state,
                submissions: action.payload,
            }


        default:
            return state;
    }
}
export default treasureBox;