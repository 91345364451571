import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import BoostedTimer from "../../../assets/images/hourglass.svg"
import BoostedRocket from "../../../assets/images/ionic-rocket.svg"


const Screen = ({ hours, minutes, seconds }) => {
    return (
        <div className="countdown-container d-flex" style={{ justifyContent: "space-between", width: "100%", textAlign: "center" }}>
            <div className="countdown-wrapper">
                <div>{zeroPad(minutes + 1)}M</div>
            </div>
        </div>
    )
}

const CountDown = ({ boostTill }) => {
    const [min, setMin] = useState(0);
    const [complete, setCompleted] = useState(false);

    const handelBootTime = (lastBoost) => {
        let boostedTill = Date.parse(lastBoost)
        let current = Date.now();
        let final = (boostedTill - current);
        if (final > 0) {
            setMin(final);
        }

    }

    useEffect(() => {
        handelBootTime(boostTill);
    }, [boostTill])

    return (
        <div>
            {min > 0 ?
                complete ? <></> :
                    <div className="boosted-detail">
                        <div className="boosted-icon boosted-time d-flex align-items-center">
                            <div className="icon timer">
                                <img src={BoostedTimer} alt="boosted" className="img-fluid" />
                            </div>
                            <span className="boosted-time">
                                <Countdown
                                    date={Date.now() + min}
                                    renderer={Screen}
                                    onComplete={() => setCompleted(true)}
                                />
                            </span>
                        </div>
                        <div className="boosted-icon boosted-rocket d-flex align-items-center">
                            <div className="icon rocket">
                                <img src={BoostedRocket} alt="boosted" className="img-fluid" />
                            </div>
                            <span>Boosted</span>
                        </div>
                    </div >
                : ""}
        </div >
    )
}

export default CountDown;